import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import styled from 'styled-components'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import {getPagedSims, getPhotos, uploadImage} from '@api/requests'
import {useAuth} from '@modules/auth'

import {toAbsoluteUrl} from '@_metronic/helpers'

import useHostBasedUrl from '@hooks/useHostBasedUrl'
import usePersonAvatars from '@hooks/usePersonAvatars'
import useRegetPerson from '@hooks/useRegetPerson'
import usePrevious from '@app/hooks/usePrevious'

import {usePhotosStore} from '@store/photos'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'
import {usePersonsStore} from '@store/persons'
import {useCultsStore} from '@pages/CultsPage/store'
import {useCultDrawerStore} from '../../cult-drawer/CultDrawer'

import CustomGallery from '@components/molecules/CustomGallery/CustomGallery'
import RelationsBlock from '@_metronic/partials/layout/sim-drawer/components/RelationsBlock'
import DescriptionSkeleton from './components/DescriptionSkeleton'
import CultSkeleton from './components/CultSkeleton'
import ParamSkeleton from './components/ParamSkeleton'

import {PersonModel} from '@api/models'

import {GALLERY_IMAGE_TARGET_SIZES} from '@constants/imageUploading'

import {RelationType} from '@app/types'
import {Fancybox} from '@fancyapps/ui'
import useCatalogsStore from '@app/store/useCatalogStore'
import Catalogs from './components/Catalogs'

const getPersonGalleryKey = (personId: number) => `sim_gallery_${personId}`

const SimDrawerContent = ({personId, readonly = false}) => {
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()
  const {selectPhoto} = usePhotosStore()

  const {persons, updatePersons, updatedPersons} = usePersonsStore()

  const [loading, setLoading] = useState(false)

  const imageRef = useRef<HTMLInputElement | null>(null)
  const [person, setPerson] = useState<PersonModel | null>(null)
  const [photos, setPhotos] = useState([])
  const [fields, setFields] = useState([])
  const [genderPronoun, setGenderPronoun] = useState<string>()

  const regetPerson = useRegetPerson(personId)
  useEffect(() => {
    if (personId) {
      setLoading(true)

      const foundPerson = persons.find(({id}) => id === personId)

      // if (foundPerson) {
      //   getPhotos(getPersonGalleryKey(personId!)).then((response) => {
      //     // console.log('### getPhotos.response', personId, response.data)
      //     // @ts-ignore
      //     setPhotos(response.data)
      //   })
      //   // @ts-ignore
      //   setFields(foundPerson.params ? foundPerson.params.fields : [])
      //   setGenderPronoun(foundPerson.params?.genderPronoun)

      //   setPerson(foundPerson)
      // }

      console.log('### personId', personId)

      getPhotos(getPersonGalleryKey(personId!)).then((r) => {
        // @ts-ignore
        setPhotos(r.data)
      })

      console.log('### regetPerson', personId)
      regetPerson()
        .then((receivedPerson) => {
          if (receivedPerson) {
            console.log('### regetPerson.receivedPerson', receivedPerson)
            // @ts-ignore
            setFields(receivedPerson.params ? receivedPerson.params.fields : [])
            setGenderPronoun(receivedPerson.params?.genderPronoun)
            setPerson(receivedPerson)
          }
        })
        .finally(() => setLoading(false))
    } else {
      setPhotos([])
      setPerson(null)
      setFields([])
    }
  }, [personId])

  useEffect(() => {
    if (updatedPersons[personId]) {
      setPerson(updatedPersons[personId])
    }
  }, [updatedPersons[personId]])

  const {currentUser} = useAuth()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()
  const clickToInput = (event) => {
    if (!currentUser?.is_premium) {
      showSubscriptionModal()
      event.preventDefault(true)
      event.stopPropagation()
      return
    }

    imageRef.current?.click()
  }
  const callBackForCrop = (file: Blob) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e?.target?.result) {
        uploadImage(file, getPersonGalleryKey(personId!)).then((response) => {
          console.log('### response', response)
          const photosCopy = photos ? [...photos] : []
          // @ts-ignore
          photosCopy.push(response.data)
          setPhotos(photosCopy)
        })
      }
    }
    reader.readAsDataURL(file)
  }

  function handleFiles(event) {
    if (!event.currentTarget.files?.length) return
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, undefined, GALLERY_IMAGE_TARGET_SIZES)

    event.currentTarget.value = ''

    if (butOpenEditorRef?.current) butOpenEditorRef.current.click()
  }

  const avatars = usePersonAvatars(personId)
  const avatarUrl = useHostBasedUrl(person?.avatar?.url)

  const handleImageUpdate = (data) => {
    console.log('### handleImageUpdate', data)

    setPhotos((oldPhotos) => {
      const newPhotos = [...oldPhotos]
      const foundIndex = newPhotos.findIndex(({id}) => id === data.id)
      console.log('### foundIndex', foundIndex)
      if (foundIndex !== -1) {
        // @ts-ignore
        newPhotos[foundIndex] = {...newPhotos[foundIndex], ...data}
      }

      return newPhotos
    })
  }

  const {cults, loadCults} = useCultsStore()
  useEffect(() => {
    if (person?.params?.cult) {
      loadCults()
    }
  }, [person])

  const {show} = useCultDrawerStore()

  const cult = useMemo(() => {
    // console.log('### person?.params?.cult', person?.params?.cult)
    if (person?.look_id && cults) return cults.find(({id}) => id === person?.look_id)
  }, [cults, person])
  // console.log('### cult', cult)

  const photoUrl = avatarUrl || 'https://my-engine.ru/modules/users/avatar.png'

  const handleCultClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    show(cult?.id)
  }

  // persons
  const prevPerson = usePrevious(person)
  useEffect(() => {
    if (person) {
      console.log('### person')
      console.log(person)
      if (!prevPerson || prevPerson.id !== person.id) {
        const petAndPersonIds = [
          ...(person.pets || []),
          ...(person.parents || []),
          ...(person.friends || []),
        ]
        const personIdMap = persons.map(({id}) => id)
        const missedPetsAndParentsIds: PersonModel['id'][] = petAndPersonIds.filter(
          (id) => !personIdMap.includes(id)
        )

        if (missedPetsAndParentsIds.length > 0) {
          getPagedSims({
            ids: missedPetsAndParentsIds,
            fields:
              'id,first_name,last_name,avatar,is_alive,is_subtree,pets,parents,partners,friends',
          }).then((response) => {
            const personPersons = response.data as PersonModel[]

            updatePersons([...persons, ...personPersons], true)
          })
        }
      }
    }
  }, [person])

  useEffect(() => {
    Fancybox.bind('[data-fancybox]', {
      // Your custom options
    })
  }, [])

  console.log('### SimDrawerContent.person', person)

  return (
    <div className='pt-5' style={{overflowX: 'hidden'}}>
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      <div className='d-flex flex-column text-center mb-9 px-9'>
        <a
          href={photoUrl}
          data-fancybox='sim-gallery'
          className={clsx('symbol symbol-150px mb-4', {'cursor-pointer': avatars.length > 0})}
        >
          {/* @ts-ignore*/}
          {avatarUrl ? (
            <img src={photoUrl} className='' alt='' style={{objectFit: 'cover'}} />
          ) : (
            <img src={photoUrl} alt='' style={{objectFit: 'cover'}} />
          )}

          {loading ? (
            <div className='d-flex flex-fill justify-content-center'>
              <span className='indicator-progress mt-5' style={{display: 'block'}}>
                {intl.formatMessage({id: 'ACTIONS.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : null}
        </a>

        <div className='text-center'>
          <span className='text-gray-800 fw-bold text-hover-primary fs-1'>
            {person && `${person.first_name} ${person.last_name}`}
          </span>

          <span className='text-muted d-block fw-semibold'>
            {person && intl.formatMessage({id: person.is_alive ? 'PERSON.ALIVE' : 'PERSON.DEAD'})}
          </span>
        </div>
      </div>

      {!readonly || (readonly && photos?.length > 0) ? (
        <div className='card border-0 shadow-none rounded-0 w-100'>
          <div className='card-header p-0 mb-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>
                {intl.formatMessage({id: 'GALLERY.TITLE'})}
              </span>
            </h3>

            <div className='card-toolbar'>
              {!readonly ? (
                <span onClick={clickToInput} className='btn btn-sm btn-light'>
                  {intl.formatMessage({id: 'ADD.PHOTO'})}
                </span>
              ) : null}
              <input
                ref={imageRef}
                style={{position: 'absolute', pointerEvents: 'none', opacity: 0}}
                type='file'
                name='avatar'
                accept='.png, .jpg, .jpeg'
                onChange={handleFiles}
              />
            </div>
          </div>

          {photos?.length > 0 ? (
            <CustomGallery readonly={readonly} photos={photos} onImageUpdate={handleImageUpdate} />
          ) : null}
        </div>
      ) : null}

      {person ? (
        <RelationsBlock
          relationType={RelationType.parent}
          readonly={readonly}
          person={person}
          loading={loading}
        />
      ) : null}
      {person ? (
        <RelationsBlock
          relationType={RelationType.friend}
          readonly={readonly}
          person={person}
          loading={loading}
        />
      ) : null}
      {person && person?.type !== 1 ? (
        <RelationsBlock
          relationType={RelationType.pet}
          readonly={readonly}
          person={person}
          loading={loading}
        />
      ) : null}
      {person &&
      (person.type !== null ||
        person.gender !== null ||
        person.other_gender ||
        person.species ||
        cult ||
        person.character_traits ||
        person.life_goals ||
        fields?.length ||
        genderPronoun ||
        person.description) ? (
        <div className='card border-0 shadow-none rounded-0 w-100'>
          <div className='card-header p-0 mb-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>
                {intl.formatMessage({id: 'INFORMATION'})}
              </span>
            </h3>
          </div>
        </div>
      ) : null}

      {person && (
        <div>
          {loading ? <ParamSkeleton /> : null}
          <div className='row mb-7 mt-7'>
            <div className='col-lg-4 fw-semibold text-muted  fs-6'>
              {intl.formatMessage({id: 'SIM.TYPE'})}
            </div>

            <div className='col-lg-8 fv-row fw-semibold text-gray-800 fs-6'>
              {person.type === 1
                ? intl.formatMessage({id: 'SIM.TYPE.PET'})
                : intl.formatMessage({id: 'SIM.TYPE.PERSON'})}
            </div>
          </div>

          {loading ? <ParamSkeleton /> : null}
          {person.gender !== 2 && person.gender !== null && (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'SIM.GENDER'})}
              </div>

              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>
                {person.gender === 0
                  ? intl.formatMessage({id: 'SELECT.GENDER.MALE'})
                  : intl.formatMessage({id: 'SELECT.GENDER.FEMALE'})}
              </div>
            </div>
          )}
          {loading ? <ParamSkeleton /> : null}
          {!loading && person.gender === 2 && person.other_gender ? (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'SIM.GENDER'})}
              </div>
              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{person.other_gender}</div>
            </div>
          ) : null}
          {loading ? <ParamSkeleton /> : null}
          {!loading && person.gender === 2 && genderPronoun ? (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'SELECT.GENDER.PRONOUNS'})}
              </div>
              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{genderPronoun}</div>
            </div>
          ) : null}
          {loading ? <ParamSkeleton /> : null}
          {/* {!loading && !!person.character_traits && (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'PERSON.PERS'})}
              </div>

              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>
                {person.character_traits}
              </div>
            </div>
          )} */}
          {!loading ? <Catalogs person={person} /> : null}
          {loading ? <ParamSkeleton /> : null}
          {/* {!loading && !!person.life_goals && (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'PERSON.GOALS'})}
              </div>

              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{person?.life_goals}</div>
            </div>
          )} */}
          {loading ? <CultSkeleton /> : null}
          {!loading && !!cult && (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'SIDEBAR.MENU.CULT'})}
              </div>
              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>
                {cult.file?.url ? (
                  <CultImage
                    src={toAbsoluteUrl(cult.file.url)}
                    alt='Pic'
                    role='button'
                    onClick={handleCultClick}
                  />
                ) : null}
                <span
                  onClick={handleCultClick}
                  role='button'
                  className='fw-semibold text-gray-800 fs-6'
                >
                  {cult.name}
                </span>
              </div>
            </div>
          )}
          {loading ? <ParamSkeleton /> : null}
          {!loading &&
            fields?.map((x, k) => (
              <div className='row mb-7' key={k}>
                <div className='col-lg-4 fw-semibold text-muted fs-6'>{x[0]}</div>
                <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{x[1]}</div>
              </div>
            ))}

          {loading ? <DescriptionSkeleton /> : null}
          {!loading && person.description ? (
            <Description
              className='lead ql-editor'
              dangerouslySetInnerHTML={{__html: person.description}}
            />
          ) : null}
        </div>
      )}
    </div>
  )
}

export default React.memo(SimDrawerContent)

const Description = styled.div`
  & blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
`

const CultImage = styled.img`
  max-width: 20px;
  max-height: 20px;

  border-radius: 15px;

  padding: 0;
  margin: 0;

  margin-right: 8px;
`
