import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import {usePersonsStore} from '@store/persons'
import {usePhotosStore} from '@store/photos'
import {useCultsStore} from '@pages/CultsPage/store'
import {useTagsStore} from '@pages/TagsPage/store'
import useCatalogsStore from '@app/store/useCatalogStore'

import useOnScreen from '@hooks/useOnScreen'
import useHostBasedUrl from '@hooks/useHostBasedUrl'
import useRegetPerson from '@hooks/useRegetPerson'

import {uploadImage} from '@api/requests'

import CustomFields from '@components/molecules/CustomFields'
import ReactQuillEditor from '@components/molecules/ReactQuillEditor'
import ThemedSelect from '@components/atoms/ThemedSelect'

import {AVATAR_IMAGE_TARGET_SIZES} from '@constants/imageUploading'

import {AvatarModel} from '@app/api/models'
import EditSimCatalogParams from './EditSimCatalogParams'

type TagOptionType = {
  label: string
  value: number
}

export type SimValuesType = {
  first_name: string
  last_name: string
  is_alive: number
  deadReason: string
  species: string
  type: number
  gender: number
  otherGender: string
  genderPronoun: string
  look: number | null
  city: string
  character_traits: string
  life_goals: string
  description: string
  avatar: AvatarModel | null
  selectedTags: TagOptionType[]
}

const DEFAULT_SIM_VALUES: SimValuesType = {
  first_name: '',
  last_name: '',
  is_alive: 1,
  deadReason: '',
  species: '',
  type: 0,
  gender: 0,
  otherGender: '',
  genderPronoun: '',
  look: null,
  city: '',
  character_traits: '',
  life_goals: '',
  description: '',
  avatar: null,
  selectedTags: [],
}

// TODO
/// display for every catalog single field
// get catalog for every

const EditSimDrawerContent = ({personId, handleValuesChange, editPersonValues}) => {
  const butOpenEditorRef = useRef<any>()

  const {tags: unSortTags, loadTags} = useTagsStore()
  const {selectPhoto} = usePhotosStore()
  const {persons} = usePersonsStore()
  const {cults, loadCults} = useCultsStore()

  const [avatar_id, setAvatarId] = useState<number | null>(null)

  const [values, setValues] = useState<SimValuesType>(DEFAULT_SIM_VALUES)
  const [customsFields, setCustomFields] = useState<any>([])
  const [catalog, setCatalog] = useState<any>({})

  useEffect(() => {
    loadCults()
  }, [])

  const tags = useMemo(
    () => unSortTags?.map((tag) => ({label: tag.name, value: tag.id})) as TagOptionType[],
    [unSortTags]
  )
  const editSimDrawerRef = useRef(null)
  const visible = useOnScreen(editSimDrawerRef)

  useEffect(() => {
    if (!visible) {
      setValues(DEFAULT_SIM_VALUES)
      setCustomFields([])
      setCatalog({})

      setAvatarId(null)
    } else {
      loadTags()
    }
  }, [visible])

  const intl = useIntl()

  const regetPerson = useRegetPerson(personId)
  useEffect(() => {
    if (personId) {
      regetPerson().then((receivedPerson) => {
        if (receivedPerson) {
          console.log('### receivedPerson', receivedPerson)
          setValues((state) => ({
            ...state,
            ...{
              first_name: receivedPerson.first_name || '',
              last_name: receivedPerson.last_name || '',
              is_alive: receivedPerson.is_alive,
              deadReason: receivedPerson.deadReason || '',
              species: receivedPerson.species || '',
              type: receivedPerson.type || DEFAULT_SIM_VALUES.type,
              gender: receivedPerson.gender,
              otherGender: receivedPerson.other_gender,
              genderPronoun: receivedPerson.params?.genderPronoun || '',
              look: receivedPerson.look_id || null,
              city: receivedPerson.city || '',
              character_traits: receivedPerson.character_traits || '',
              life_goals: receivedPerson.life_goals || '',
              description: receivedPerson.description || '',
              avatar: receivedPerson.avatar || null,
              selectedTags: receivedPerson.tags.map(({id, name}) => ({label: name, value: id})),
            },
          }))

          setCustomFields(receivedPerson.params ? receivedPerson.params.fields : [])
          setCatalog(receivedPerson.params ? receivedPerson.params.catalog : {})
          // @ts-ignore
          setAvatarId(receivedPerson.avatar ? receivedPerson.avatar.id : null)
        }
      })
    }
  }, [personId])

  useEffect(() => {
    // @ts-ignore
    const foundPerson = persons.find((x) => parseInt(x.id) === personId)

    if (foundPerson) {
      const {
        avatar,
        first_name,
        species,
        last_name,
        gender,
        selectedTags,
        otherGender,
        is_alive,
        deadReason,
        type,
        city,
        look,
        character_traits,
        life_goals,
        description,
        genderPronoun,
      } = values

      handleValuesChange({
        avatar,
        first_name,
        species,
        last_name,
        avatar_id,
        gender,
        tags: selectedTags.map(({value}) => value),
        other_gender: otherGender,
        is_alive,
        deadReason,
        type,
        city,
        look_id: look,
        character_traits,
        life_goals,
        description,
        // @ts-ignore
        params: {
          ...foundPerson.params,
          fields: customsFields ? customsFields : [],
          genderPronoun,
          catalog,
        },
      })
    }
  }, [values, customsFields, catalog, avatar_id])

  const callBackForCrop = (file) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e && e.target && e.target.result) {
        uploadImage(file, 1).then((response) => {
          setAvatarId(response.data.id)
          setValues((state) => ({...state, avatar: response.data}))
        })
      }
    }
    reader.readAsDataURL(file)
  }

  const editAvatar = (event) => {
    console.log('### editAvatar', avatarUrl)

    if (avatarUrl) {
      // @ts-ignore
      fetch(avatarUrl, {mode: 'no-cors'})
        .then((r) => {
          console.log('### editAvatar.r')
          return r.blob()
        })
        .then((blob) => {
          console.log('### editAvatar.blob')
          selectPhoto(blob, callBackForCrop, 1, AVATAR_IMAGE_TARGET_SIZES, true, true)
        })
        .catch((error) => {
          console.log('### editAvatar.error', error)
        })
    }
  }
  function handleFiles(event) {
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, 1, AVATAR_IMAGE_TARGET_SIZES, false, true)

    event.currentTarget.value = ''

    if (butOpenEditorRef && butOpenEditorRef.current) {
      //@ts-ignore
      butOpenEditorRef.current.click()
    }
  }

  const avatarUrl = useHostBasedUrl(values.avatar?.url)

  console.log('### values', values)
  console.log('### values.character_traits', values.character_traits)

  return (
    <div
      className='card-body hover-scroll-overlay-y h-400px pt-5'
      id='kt_help_body'
      ref={editSimDrawerRef}
    >
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      {/* begin::Content */}
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: 'USER.PHOTO'})}
        </label>
        <div className='col-lg-12'>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              style={{
                backgroundImage: avatarUrl
                  ? //@ts-ignore
                    `url('${avatarUrl}')`
                  : 'url("https://my-engine.ru/modules/users/avatar.png")',
              }}
              className='image-input-wrapper w-125px h-125px'
            />
            <div
              className='d-flex flex-row w-100 justify-content-end column-gap-1'
              style={{left: 0, transform: 'translateX(0) translateY(-10px)'}}
              data-kt-image-input-action='change'
            >
              {avatarUrl && (
                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  title='Edit avatar'
                  onClick={editAvatar}
                >
                  <i className='ki-duotone ki-setting-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </label>
              )}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                <i className='ki-duotone ki-pencil fs-7'>
                  <span className='path1' />
                  <span className='path2' />
                </i>
                <input
                  onChange={handleFiles}
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                />
                <input type='hidden' name='avatar_remove' />
              </label>
            </div>

            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='ki-duotone ki-cross fs-2'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          </div>
          <div className='form-text'>{intl.formatMessage({id: 'TREE.ADD.PHOTO.FORMAT'})}</div>
        </div>
      </div>
      <div className='mb-10'>
        <label className='required form-label'>{intl.formatMessage({id: 'USER.FIRST_NAME'})}</label>
        <input
          type='text'
          value={values.first_name}
          className='form-control'
          placeholder=''
          onChange={(e) => setValues({...values, first_name: e.currentTarget.value})}
        />
      </div>
      <div className='mb-10'>
        <label className='required form-label'>{intl.formatMessage({id: 'USER.LAST_NAME'})}</label>
        <input
          type='text'
          value={values.last_name}
          className='form-control'
          placeholder=''
          onChange={(e) => setValues({...values, last_name: e.currentTarget.value})}
        />
      </div>
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIM.GENDER'})}</label>
        <select
          value={values.gender}
          className='form-select'
          aria-label='Select example'
          onChange={(e) => setValues({...values, gender: +e.currentTarget.value})}
        >
          <option>{intl.formatMessage({id: 'SELECT.GENDER'})}</option>
          <option value={0}>{intl.formatMessage({id: 'SELECT.GENDER.MALE'})}</option>
          <option value={1}>{intl.formatMessage({id: 'SELECT.GENDER.FEMALE'})}</option>
          <option value={2}>{intl.formatMessage({id: 'SELECT.GENDER.OTHER'})}</option>
        </select>
      </div>
      {values.gender === 2 ? (
        <>
          <div className='mb-10'>
            <label className=' form-label'>{intl.formatMessage({id: 'SIM.OTHER_GENDER'})}</label>
            <input
              type='text'
              value={values.otherGender}
              className='form-control'
              placeholder={intl.formatMessage({id: 'SIM.GENDER.ENTER'})}
              onChange={(e) => setValues({...values, otherGender: e.currentTarget.value})}
            />
          </div>
          <div className='mb-10'>
            <label className=' form-label'>
              {intl.formatMessage({id: 'SELECT.GENDER.PRONOUNS'})}
            </label>
            <input
              type='text'
              value={values.genderPronoun}
              className='form-control'
              placeholder=''
              onChange={(e) => setValues({...values, genderPronoun: e.currentTarget.value})}
            />
          </div>
        </>
      ) : null}
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIM.CONDITION'})}</label>
        <select
          value={values.is_alive}
          className='form-select'
          aria-label='Select example'
          onChange={(e) => setValues({...values, is_alive: +e.currentTarget.value})}
        >
          <option>{intl.formatMessage({id: 'SIM.CONDITION.SELECT'})}</option>
          <option value={1}>{intl.formatMessage({id: 'SIM.CONDITION.ALIVE'})}</option>
          <option value={0}>{intl.formatMessage({id: 'SIM.CONDITION.DEAD'})}</option>
        </select>
      </div>
      {/*{alive === 0 ? (
        <div className='mb-10'>
          <label className=' form-label'>Причина смерти</label>
          <input
            type='email'
            value={deadReason}
            onChange={(e) => setDeadReason(e.currentTarget.value)}
            className='form-control form-control-solid'
            placeholder=''
          />
        </div>
      ) : null}*/}
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIM.TYPE'})}</label>
        <select
          value={values.type}
          className='form-select'
          aria-label='Select example'
          onChange={(e) => setValues({...values, type: +e.currentTarget.value})}
        >
          <option>{intl.formatMessage({id: 'SIM.TYPE.SELECT'})}</option>
          <option value={0}>{intl.formatMessage({id: 'SIM.TYPE.PERSON'})}</option>
          <option value={1}>{intl.formatMessage({id: 'SIM.TYPE.PET'})}</option>
        </select>
      </div>
      <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'SIDEBAR.MENU.TAGS'})}</label>
        <ThemedSelect
          value={values.selectedTags}
          onChange={(selectedTag) =>
            setValues({...values, selectedTags: selectedTag as TagOptionType[]})
          }
          placeholder={intl.formatMessage({id: 'SIM.TAG.SELECT'})}
          className='react-select-styled'
          classNamePrefix='react-select'
          isMulti
          options={tags}
        />
      </div>
      <div className='mb-10'>
        <label className='form-label'>{intl.formatMessage({id: 'SIDEBAR.MENU.CULT'})}</label>
        <select
          value={values.look || ''}
          className='form-control'
          placeholder=''
          onChange={(e) => setValues({...values, look: +e.currentTarget.value})}
        >
          <option>{intl.formatMessage({id: 'SIM.CULT.SELECT'})}</option>
          {cults?.map(({id, name, file}) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </div>

      {/* <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'PERSON.PERS'})}</label>
        <input
          type='text'
          value={values.character_traits}
          className='form-control'
          placeholder=''
          onChange={(e) => setValues({...values, character_traits: e.currentTarget.value})}
        />
      </div> */}

      <EditSimCatalogParams
        allSimValues={values}
        values={catalog}
        onChange={setCatalog}
        onAllValuesChange={(updatedValues) => {
          console.log('### onAllValuesChange', updatedValues)
          setValues(updatedValues)
        }}
      />

      {/* <div className='mb-10'>
        <label className=' form-label'>{intl.formatMessage({id: 'PERSON.GOALS'})}</label>
        <input
          type='text'
          value={values.life_goals}
          className='form-control '
          placeholder=''
          onChange={(e) => setValues({...values, life_goals: e.currentTarget.value})}
        />
      </div> */}

      <CustomFields allowCopy values={customsFields} onChange={setCustomFields} />

      <div className='mb-10'>
        <label className='form-label'>{intl.formatMessage({id: 'TREE.ADD.DESCRIPTION'})}</label>
        <ReactQuillEditor
          initValue={values.description}
          setValue={(value) => setValues({...values, description: value})}
        />
      </div>
      {/* end::Content */}
    </div>
  )
}

export {EditSimDrawerContent}
