import React, {useMemo} from 'react'
import Select, {StylesConfig} from 'react-select'
import {StateManagerProps} from 'react-select/dist/declarations/src/useStateManager'

type ThemedSelectProps = StateManagerProps & {style?: any}
const ThemedSelect = ({style, ...props}: ThemedSelectProps) => {
  const themedStyle = useMemo(() => {
    const backgroundColor = 'var(--bs-body-bg)' // darkMode ? DARK_COLOR : LIGHT_COLOR

    const colourStyles: StylesConfig = {
      control: (styles) => ({
        ...styles,
        backgroundColor: backgroundColor,
        borderColor: 'var(--bs-gray-300)',
      }),
      option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? backgroundColor
            : isFocused
            ? `${backgroundColor}CC`
            : backgroundColor,

          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? backgroundColor
                : `${backgroundColor}60`
              : undefined,
          },
        }
      },
      input: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-700)',
        'font-size': '1.1rem',
        'font-weight': 500,
        'line-height': 1.5,
      }),
      placeholder: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-700)',
        'font-size': '1.1rem',
        'font-weight': 500,
        'line-height': 1.5,
      }),
      singleValue: (styles, {data}) => ({...styles}),
      menu: (styles) => ({...styles, backgroundColor}),
      indicatorSeparator: (styles) => ({...styles, backgroundColor: 'var(--bs-gray-300)'}),
      dropdownIndicator: (styles) => ({
        ...styles,
        color: 'var(--bs-gray-500)',
        width: '35px',
        'margin-right': '3px',
      }),
    }

    return colourStyles
  }, [])

  return <Select styles={{...themedStyle, ...style}} {...props} />
}

export default React.memo(ThemedSelect)
