import React, {useEffect, useMemo} from 'react'
import {styled} from 'styled-components'

import {addHostPrefixToUrl} from '@app/hooks/useHostBasedUrl'
import useCatalogsStore from '@app/store/useCatalogStore'

import {PersonModel} from '@app/api/models'
import LabelWithImage from '@_metronic/partials/layout/editsim-drawer/EditSimCatalogParams/components/CatalogField/components/LabelWithImage'

import {useLang} from '@_metronic/i18n/Metronici18n'

type CatalogsProps = {
  person: PersonModel
}
const Catalogs = ({person}: CatalogsProps) => {
  console.log('### Catalogs', person?.params.catalog)
  const {loadCatalogs, catalogs} = useCatalogsStore()

  useEffect(() => {
    console.log('### catalogs', catalogs)
    if (person?.params.catalog && Object.keys(person?.params.catalog).length > 0) {
      loadCatalogs()
    }
  }, [])
  console.log('### person', person?.params.catalog)

  const catalogsWithOutdated = useMemo(() => {
    console.log('### catalogsWithOutdated.catalogs', catalogs)
    return catalogs.map((catalog) => {
      console.log('### catalog', catalog)
      const outdatedValue = person[catalog.code_name]
        ? {
            id: person[catalog.code_name],
            name_en: person[catalog.code_name],
            name_ru: person[catalog.code_name],
            outdated: true,
            image: '',
          }
        : undefined
      console.log('### outdatedValue', outdatedValue)
      const catalogValues = person?.catalog ? person?.catalog[catalog.code_name] : []

      const resValues = (outdatedValue ? [outdatedValue] : [])
        // @ts-ignore
        .concat(catalogValues || [])
        .map((value) => ({...value, image: addHostPrefixToUrl(value.image)}))

      return {
        ...catalog,
        values: resValues,
      }
    })
  }, [person, catalogs])

  console.log('### catalogsWithOutdated', catalogsWithOutdated)

  const language = useLang()
  const isRu = language === 'ru'

  return (
    <>
      {catalogsWithOutdated.map((catalog) => {
        if (!catalog.values.length) return null

        console.log('### catalog.code_name', catalog.code_name)
        return (
          <div key={catalog.code_name} className='row mb-7'>
            <div className='col-lg-4 fw-semibold text-muted  fs-6'>
              {isRu ? catalog.name_ru : catalog.name_en}
            </div>

            <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>
              {catalog.values.map((value) => (
                // @ts-ignore
                <LabelWithImageStyled
                  key={value.id}
                  imageUrl={value.image}
                  label={isRu ? value.name_ru : value.name_en}
                />
              ))}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default React.memo(Catalogs)

const LabelWithImageStyled = styled(LabelWithImage)`
  padding-left: 0;

  padding-top: 0;
  margin-top: 0;
`
