import React from 'react'
import styled from 'styled-components'

type LabelWithImageProps = {
  className?: string
  imageUrl?: string
  label: string
}
const LabelWithImage = ({className, imageUrl, label}: LabelWithImageProps) => {
  return (
    <Wrapper className={className}>
      {imageUrl ? <Image src={imageUrl} /> : null}
      {label}
    </Wrapper>
  )
}

export default React.memo(LabelWithImage)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  cursor: pointer;

  padding-left: 8px;

  &:hover {
    opacity: 0.8;
  }
`

const Image = styled.img`
  margin-right: 8px;
  height: 20px;
`
