import React from 'react'
import styled from 'styled-components'

const OptionWithImage = (props) => {
  const {innerRef, innerProps, data} = props

  return (
    <Wrapper ref={innerRef} {...innerProps}>
      {data.imageUrl ? <Image src={data.imageUrl} /> : null}
      {data.label}
    </Wrapper>
  )
}

export default React.memo(OptionWithImage)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  cursor: pointer;

  padding-left: 8px;

  &:hover {
    opacity: 0.8;
  }
`

const Image = styled.img`
  margin-right: 8px;
  height: 20px;
`
