import React, {useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {useLang} from '@_metronic/i18n/Metronici18n'

import useCatalogsStore, {CatalogType} from '@app/store/useCatalogStore'

import ThemedSelect from '@app/components/atoms/ThemedSelect'
import {addHostPrefixToUrl} from '@app/hooks/useHostBasedUrl'

import LabelWithImage from './components/LabelWithImage'
import OptionWithImage from './components/OptionWithImage'

type CatalogFieldProps = {
  catalog: CatalogType
  values: number[]
  outdatedValue: any
  onChange: (value: any) => void
  onDeleteOutdatedValue: () => void
}
const CatalogField = ({
  catalog,
  values,
  outdatedValue,
  onChange,
  onDeleteOutdatedValue,
}: CatalogFieldProps) => {
  // console.log('### CatalogField', catalog.code_name, values)
  const {code_name} = catalog
  //   const intl = useIntl()

  const {loadCatalogData, catalogsData} = useCatalogsStore()
  const catalogData = catalogsData[code_name]
  const mappedCatalogData = useMemo(() => {
    return catalogData?.data.reduce((acc, data) => {
      acc[data.id] = data

      return acc
    }, {})
  }, [catalogData?.data])

  //   console.log('### catalog', catalog, catalogsData)

  useEffect(() => {
    if (code_name) {
      loadCatalogData(code_name)
    }
  }, [])

  const language = useLang()
  const isRu = language === 'ru'
  console.log('## isRu', isRu)
  const catalogName = isRu ? catalog.name_ru : catalog.name_en

  const options = useMemo(() => {
    const catalogOptions = (catalogData?.data || []).map(({id, name_ru, name_en, params}) => ({
      value: id,
      label: isRu ? name_ru : name_en,
      imageUrl: addHostPrefixToUrl(params?.image),
    }))

    if (outdatedValue) {
      catalogOptions.unshift(outdatedValue)
    }

    return catalogOptions
  }, [catalogData?.data, outdatedValue, isRu])

  const valuesWithOutdated = useMemo(() => {
    return (outdatedValue ? [outdatedValue] : []).concat(
      values
        .map((value) => mappedCatalogData[value])
        .map(({id, name_ru, name_en, params}) => ({
          value: id,
          label: isRu ? name_ru : name_en,
          imageUrl: addHostPrefixToUrl(params?.image),
        }))
    )
  }, [outdatedValue, values, mappedCatalogData, isRu])

  console.log('### valuesWithOutdated', valuesWithOutdated)

  const handleChange = (updatedValues) => {
    console.log('### handleChange', updatedValues)

    const updatedValuesValues = updatedValues.map(({value}) => value)
    const deletedValues = valuesWithOutdated.filter(
      ({value}) => updatedValuesValues.indexOf(value) === -1
    )
    console.log('### deletedValues', deletedValues)

    if (deletedValues.find(({outdated}) => !!outdated)) {
      onDeleteOutdatedValue()
    }

    onChange(updatedValues.filter(({outdated}) => !outdated).map(({value}) => value))
  }

  return (
    <div className='mb-10'>
      <label className=' form-label'>{catalogName}</label>
      {!catalog || catalog.loading ? (
        <Loader className='indicator-progress'>
          <span className='spinner-border spinner-border align-middle ms-2' />
        </Loader>
      ) : (
        <ThemedSelect
          value={valuesWithOutdated}
          placeholder={catalogName}
          className='react-select-styled'
          classNamePrefix='react-select'
          isMulti
          options={options}
          components={{Option: OptionWithImage}}
          onChange={handleChange}
          formatOptionLabel={(value) => {
            // console.log('### value', value)
            // @ts-ignore
            return <LabelWithImage {...value} />
          }}
        />
      )}
    </div>
  )
}

export default React.memo(CatalogField)

const Loader = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
`
