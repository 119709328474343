import React, {useEffect} from 'react'

import useCatalogsStore from '@app/store/useCatalogStore'

import CatalogField from './components/CatalogField'

import {SimValuesType} from '../EditSimDrawerContent'

const mapCatalogNameToOutdatedFieldName = {
  character_traits: 'character_traits',
  animal_personality_traits: 'character_traits',
  life_goals: 'life_goals',
}

type EditSimCatalogParamsProps = {
  allSimValues: SimValuesType
  values: any
  onChange: (updatedValues: any) => void
  onAllValuesChange: (updatedValues: SimValuesType) => void
}
const EditSimCatalogParams = ({
  allSimValues,
  values = {},
  onChange,
  onAllValuesChange,
}: EditSimCatalogParamsProps) => {
  console.log('### EditSimCatalogParams', values, allSimValues.type)
  const {loadCatalogs, catalogs} = useCatalogsStore()

  useEffect(() => {
    loadCatalogs()
  }, [])

  const catalogsFilteredByType = catalogs?.filter((catalog) => {
    console.log('### allSimValues.type', allSimValues.type)
    if (allSimValues.type === 0) {
      return catalog.code_name !== 'animal_personality_traits'
    }
    return catalog.code_name !== 'character_traits'
  })

  console.log('### catalogsFilteredByType', allSimValues.type, catalogsFilteredByType)

  return (
    <>
      {catalogsFilteredByType?.map((catalog) => {
        const outdatedFieldName = mapCatalogNameToOutdatedFieldName[catalog.code_name]
        // console.log('### outdatedFieldName', outdatedFieldName)
        const outdatedFieldValue = outdatedFieldName ? allSimValues[outdatedFieldName] : undefined

        const outdatedValue = outdatedFieldValue
          ? {value: outdatedFieldValue, label: outdatedFieldValue, outdated: true}
          : null

        const fieldValues = values[catalog.code_name] || []
        console.log('### fieldValues', fieldValues)

        return (
          <CatalogField
            key={catalog.code_name}
            catalog={catalog}
            values={fieldValues}
            outdatedValue={outdatedValue}
            onChange={(value) => {
              console.log('### onChange', value)
              onChange({...values, [catalog.code_name]: value})
            }}
            onDeleteOutdatedValue={() => {
              console.log('### onDeleteOutdatedValue', outdatedFieldName)
              const allSimValuesClone = {...allSimValues}
              allSimValuesClone[outdatedFieldName] = ''
              onAllValuesChange(allSimValuesClone)
            }}
          />
        )
      })}
    </>
  )
}

export default React.memo(EditSimCatalogParams)
